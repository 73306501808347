/* PORTFOLIO START */
#portfolio {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
}

@media (min-width: 500px) {
  #portfolio {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media (min-width: 1000px) {
  #portfolio {
    grid-template-columns: repeat(3, 1fr);
  }
  .portfolio-item {
    max-height: 550px !important;
  }
}

@media (min-width: 1400px) {
  #portfolio {
    grid-template-columns: repeat(4, 1fr);
  }
  .portfolio-item {
    max-height: 600px !important;
  }
}

#portfolio a:hover {
  padding-bottom: 0;
  border: none;
}

.portfolio-item {
  width: 100%;
  height: 100%;
  max-height: 500px;
  opacity: 0.9;
  cursor: pointer;
  text-align: center;

  border-radius: 2px;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.portfolio-item img:hover {
  opacity: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.portfolio-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* PORTFOLIO END */
