header {
  max-width: 600px;
  margin: 50px auto;
  margin-bottom: 100px;
  text-align: center;
}

/* header p {
  text-align: left;
} */

header #contact {
  font-size: small;
  font-style: italic;
}
header #contact span {
  display: inline-block;
}

header img {
  width: 300px;
  height: 300px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

header input,
header textarea {
  color: inherit;
  background: inherit;
  border: none;
  width: 100%;
  font: inherit;
}

header input:focus,
header textarea:focus {
  outline-color: darkblue;
  /* outline-style: dashed;
  outline-width: 1px; */
}

#save-changes-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 56px;
  background: white;
  /* background: rgba(0, 0, 139, 0.9); */
  box-shadow: 0 0 3px grey;
  display: flex;
  justify-content: center;
}

#save-changes-bar button,
#save-changes-bar button:active,
#save-changes-bar button:focus {
  padding-left: 20px;
  padding-right: 20px;
  background: rgb(100, 100, 100);
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
#save-changes-bar button:hover {
  background: rgb(75, 75, 75);
  box-shadow: 0 0 5px rgb(75, 75, 75);
}

#save-changes-bar button.primary,
#save-changes-bar button.primary:active,
#save-changes-bar button.primary:focus {
  padding-left: 20px;
  padding-right: 20px;
  background: rgb(75, 75, 255);
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
#save-changes-bar button.primary:hover {
  background: rgb(50, 50, 255);
  box-shadow: 0 0 5px rgb(50, 50, 255);
}

@media (min-width: 1000px) {
  header img {
    width: 400px;
    height: 400px;
  }
}
