#modal {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: #f9fbfc;
  /* background-color: rgba(255, 255, 255, 0.95); */
}

#modal nav {
  text-align: center;
}
#modal nav button {
  background: none;
  border: none;
}

#modal section {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 60px; /* For the close-Button */
}

#modal section #image-wrapper {
  width: 100%;
  max-width: 100vw;
  text-align: center;
}

#modal section img {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  max-width: 100%;
  max-height: calc(95vh - 60px);
  object-fit: contain;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

#modal section article {
  padding: 10px;
}

#modal section article #year {
  margin: 0;
}

#modal footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  /* padding: 10px; */
  text-align: center;
  background: #f5f6f6;
  display: flex;
  justify-content: space-between;
}

#modal footer button {
  height: 48px;
  width: 100%;
  flex: 1 auto;
  background: none;
  border: none;
  cursor: pointer;
}

@media (min-width: 1000px) {
  #modal section {
    min-height: calc(95vh - 60px);
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 20px;
  }

  #modal section article {
    padding: 30px;
  }

  #modal section h1,
  #modal section input {
    font-size: xx-large;
    font-weight: 900;
  }

  #modal section img {
    border-radius: 2px;
  }

  #modal footer button {
    height: 64px;
  }
}
